import SmoothScroll from 'smooth-scroll';
export const Menu = {
	classes: {
		root: '.js-menu',
		item: '.js-scroll',
	},
	init() {
		new SmoothScroll(this.classes.item, {
			speed: 1000,
			speedAsDuration: true
		});
	},
};