import { Menu } from '../../../molecules/Navigation/menu/menu';
import { Hamburger } from "../../../atoms/Global/hamburger/hamburger";
import { ModuleLang } from "../../../molecules/Blocks/lang/lang";
export default {
	classes: {
		root: '.js-header',
		hamburger: '.js-hamburger',
		navigation: '.js-header-navigation',

		// State
		rootActive: 'o-header--active',
		navActive: 'o-header__navigation--show'
	},
	init() {
		const { hamburger, navActive } = this.classes;
		const navigation = document.querySelector(this.classes.navigation);

		Menu.init();
		ModuleLang.init();
		let prev = window.pageYOffset;
		let windowWidth = window.innerWidth;

		window.addEventListener('resize', () => windowWidth = window.innerWidth);
		window.addEventListener('scroll', () => {
			prev = this.checkDirection(prev, windowWidth);
			window.pageYOffset > 100 ? this.handleActive(true) : this.handleActive(false); 
		});
		document.querySelector(hamburger).addEventListener("click", () => this.handleNav(navigation));
		document.querySelector('.js-wrapper').addEventListener("click", () => { navigation.classList.contains(navActive) && this.handleNav(navigation) });

	},
	checkDirection(prev, windowWidth) {
		const needHide = window.pageYOffset < window.innerHeight || parseInt(window.pageYOffset + window.innerHeight) === document.body.offsetHeight;
	
		if (needHide) {
			this.handleShow('up')
		} else {
				if (window.pageYOffset > prev && windowWidth > 768) {
					this.handleShow('down')
				} else {
					this.handleShow('up')
				}
		}
		return window.pageYOffset
	},
	handleActive(active = false) {
		const root = document.querySelector(this.classes.root);
		if (active) {
			root.classList.add(this.classes.rootActive);
		}
		else {
			root.classList.remove(this.classes.rootActive);
		}
	},
	handleShow(type) {
		const root = document.querySelector(this.classes.root);
		if (type === 'down') {
			root.style.transform = 'translateY(-100%)';
		}
		else {
			root.style.transform = 'translateY(0)';
		}
	},
	handleNav(navigation) {
		Hamburger.toggleClass();
		navigation.classList.toggle(this.classes.navActive);
	}
};
