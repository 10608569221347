import IMask from 'imask';
import 'materialize-css/dist/js/materialize.min.js'
import fade from '../../../../_scripts/settings/fade';

export const FormContacts = {
	classes: {
		root: '.js-contacts',
		phone: '.js-phone',
		field: '.js-field',
		map:'.js-map',

		mapSuccess:'o-section-contacts__map--success'
	},
	init() {
		const root = document.querySelector(this.classes.root);
		document.addEventListener('DOMContentLoaded', () => {
			this.initFields(root)
		});
		this.getData(root);
	},
	initFields(root) {
		const phone = root.querySelector(this.classes.phone);
		new IMask(phone, { mask: '+{38}(000)00-00-000' });
	},
	getData(root) {
		const fieldElements = root.querySelectorAll(this.classes.field);
		const method = root.getAttribute('method');
		const path = root.getAttribute('data-path');
		const csrfToken = root.querySelectorAll('input')[0].value;
		let data = {};
		root.addEventListener('submit', (e) => {
			e.preventDefault();
			fieldElements.forEach(item => {
				const key = item.getAttribute('name');
				const element = JSON.parse(`{"${key}":"${item.value}"}`);
				data = Object.assign({}, data, element);
			})
			data = Object.assign({}, data, JSON.parse(`{"form":"contacts"}`));
			const isValid = Object.values(data).some(item=> item == "");
			
			!isValid && this.sendData(path, method, data, csrfToken)
		})
	},
	sendData(path, method, params, csrfToken) {
		console.log(params);
		
		const headers = new Headers({
			"Content-Type": "application/json; charset=utf-8",
			'X-CSRFToken': csrfToken,
			
		})
		fetch(path, {
			method,
			credentials: 'include',
			headers,
			body: JSON.stringify(params),
		}).then(response => response.ok && this.success())
		
	},
	success(){
		const root = document.querySelector(this.classes.root);
		const map = document.querySelector(this.classes.map);

		fade.out(root);
		map.classList.add(this.classes.mapSuccess);
	}
};
