import { StaffItem } from './../../../molecules/Blocks/staff/staff';
export default {
	classes: {
		root: '.js-staff',
		container: '.js-items',
		json: '.js-json'
	},
	init() {
		const root = document.querySelector(this.classes.root);

		const container = root.querySelector(this.classes.container);
		const data = root.querySelector(this.classes.json).innerHTML;

		// this.fetchAsync('https://my-json-server.typicode.com/RomanStrutynskyi/jsonserver/staff').then(data => {
			this.render(JSON.parse(data), container);
		// });
	},
	// async fetchAsync(patch) {
	// 	let response = await fetch(patch);
	// 	let data = await response.json();
	// 	console.log(JSON.stringify(data));
		
	// 	return data;
	// },
	elementsScheme(data) {
		let dataLength = data.length;
		const scheme = [];
		while (dataLength !== 0) {
			if (dataLength > 5 || dataLength === 4) {
				scheme.push(4);
				dataLength -= 4;
			} else if (dataLength === 5) {
				scheme.push(3);
				dataLength -= 3;
			} else if (dataLength === 3) {
				if (scheme[scheme.length - 1] === 3) {
					scheme.push(2);
					dataLength -= 2;
				} else {
					scheme.push(3);
					dataLength -= 3;
				}
			} else if (dataLength === 2) {
				scheme.push(2);
				dataLength -= 2;
			} else {
				scheme.push(1);
				dataLength -= 1;
			}
		}

		return scheme;
	},
	render(data, container) {
		const scheme = this.elementsScheme(data);
		let indexItem = 0;
		let items = ''
		scheme.forEach((count) => {
			const offset = 4 - count;

			for (let rowItem = 0; rowItem < count; rowItem++) {
				//Render item
				const item = StaffItem.render(data[indexItem], rowItem === 0 ? offset : 0);
				// console.log(item);
				items= items.concat(item)
				indexItem++;
			}
		})
		container.innerHTML =items;		
	}
}