import { Power4, TimelineLite } from "gsap";
require('waypoints/lib/noframework.waypoints.min');
import media from '../../../../_scripts/settings/media'
export default {
	classes: {
		root: '.js-process',
		item: '.js-process-item'
	},
	tl: new TimelineLite(),
	init() {
		if (window.innerWidth < media.Tablet) {
			this.tl.set(this.classes.item, { x: 0,  opacity: 1 })
		} else { 
			this.tl.set(this.classes.item, { x: -100, opacity: 0 })
		}

		new Waypoint({
			element: document.querySelector(this.classes.root),
			handler: () => this.process(),
			offset: '50%'
		})
	},
	process() {
		this.tl.staggerTo(this.classes.item, .25, {
			ease: Power4.easeInOut,
			x: 0,
			y: 0,
			opacity: 1,
		}, .15);
	}
}