export const Hamburger = {
  properties:{
    // Classes
    root:'.js-hamburger',
    // States
    active:'a-hamburger--active'
  },
  toggleClass(){
    const {root, active} = this.properties;
    const rootSelector = document.querySelector(root)
    rootSelector.classList.toggle(active);
  }
}