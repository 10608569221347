// =========================================
// Media Queries
// =========================================
export default {
  IPhone: 320,
  Phone: 480,
  MaxPhone: 580,
  Tablet: 768,
  Desktop: 992,
  Screen:1200,
  Large:1600
};
