import IMask from 'imask';
import 'materialize-css/dist/js/materialize.min.js';
import fade from './../../../../_scripts/settings/fade';
export const FormCalculation = {
	classes: {
		root: '.js-calculation',
		phone: '.js-phone',
		field: '.js-field',
		select: '.js-select',
		thanks: '.js-calculation-thanks'
	},
	init() {
		const root = document.querySelector(this.classes.root);
		document.addEventListener('DOMContentLoaded', () => {
			this.initFields(root)
		});
		this.getData(root);
	},
	initFields(root) {
		const phone = root.querySelector(this.classes.phone);
		const selects = root.querySelectorAll(this.classes.select);

		M.FormSelect.init(selects, {});
		new IMask(phone, { mask: '+{38}(000)00-00-000' });
	},
	getData(root) {
		const fieldElements = root.querySelectorAll(this.classes.field);
		const method = root.getAttribute('method');
		const path = root.getAttribute('data-path');
		const csrfToken = root.querySelectorAll('input')[0].value;
		let data = {};
		root.addEventListener('submit', (e) => {
			e.preventDefault();
			fieldElements.forEach(item => {
				const key = item.getAttribute('name');
				const element = JSON.parse(`{"${key}":"${item.value}"}`);
				data = Object.assign({}, data, element);
			})
			data = Object.assign({}, data, JSON.parse(`{"form":"calculation"}`));
			const isValid = Object.values(data).some(item=> item == "");
			
			!isValid && this.sendData(path, method, data, csrfToken)
		})
	},
	sendData(path, method, params, csrfToken) {
		const headers = new Headers({
			"Content-Type": "application/json; charset=utf-8",
			'X-CSRFToken': csrfToken,
		})
		fetch(path, {
			method,
			credentials: 'include',
			headers,
			body: JSON.stringify(params),
		}).then(response => response.ok && this.success())
	},
	success(){
		const root = document.querySelector(this.classes.root);
		const thanks = document.querySelector(this.classes.thanks);

		fade.out(root);
		setTimeout(() => {
			fade.in(thanks, 'inline-flex');
		}, 300);
	}
};
