// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';
// ------------------------------
// Imports
// ------------------------------
import './menu';

import Config from './settings/config';

// import $ from 'jquery';
// export for others scripts to use
// window.$ = $;


// Modules
// import Link from './../_modules/molecules/menu/menu';

// Organisms
import { FormCalculation } from './../../src/_modules/molecules/Forms/form-calculation/form-calculation';
import Header from './../_modules/organisms/Global/header/header';
import Staff from './../_modules/organisms/Sections/section-staff/section-staff';
import Process from './../_modules/organisms/Sections/section-process/section-process';
import Contacts from './../_modules/organisms/Sections/section-contacts/section-contacts';
// import Rangle from './../_modules/organisms/rangle-tabs/rangle-tabs'
// //Molecules 
// import Tabs from './../_modules/molecules/tabby/tabby'  


// ------------------------------
// Additional functionality
// ------------------------------

// Crossbrowser plugin for icons
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js';
svg4everybody();

console.log(Config);

// Data pages
// let currentPage = $('body').data('page');

// ------------------------------
// Initialization modules
// ------------------------------


Header.init();
FormCalculation.init();
Process.init();
Staff.init();
Contacts.init();
// $(() => {
	//   // Common scripts
	
	//   // Home page scripts
	//   // new Slider();
	//   // new Tabs();
	//   // new Rangle();
	//   // if (currentPage === pages.home.name) {
		//   //   new Link();
		//   // }
		// });
		
		document.querySelectorAll('input').forEach(item => item.hasAttribute('data-text') && item.setAttribute('type', 'text'));