import langChecker from "../../../../_scripts/settings/langChecker"
export const ModuleLang = {
  classes:{
    root:'.js-lang-item',
    // States
    active:'m-lang__item--active'
  },
  init(){
    const {root, active}= this.classes;
    document.querySelector(`${root}[data-lang-item=${langChecker.init()}]`).classList.add(active);
    
  }
}