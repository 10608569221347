'use strict';

export const StaffItem = {

	render(data, offset = 0) {
		const { image, full_name: fullName, position } = data
		let offsetClass = offset != 0 ? `offset-xl-${offset * 3}` : ''
		return (
			`<div class="m-staff col-sm-6 col-md-4 col-xl-3 ${offsetClass}">
				<img src=${image} alt=${fullName} class="m-staff__img">
				<div class="m-staff__info">
					<div class="m-staff__name">${fullName} </div>
					<div class="m-staff__position">${position}</div>
				</div>
			</div>`
		)

	}
}
